var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dictionary && Object.keys(_vm.dictionary).length > 0)?_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, errors }){return [_c('form',{staticClass:"login",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"ym-toptitle-box"},[_c('fixed-header',{attrs:{"threshold":0,"fixedClass":"ym-toptitle-fixed"}},[_c('div',{staticClass:"level ym-toptitle",attrs:{"id":"toptitle"}},[_c('div',{staticClass:"level-left"},[_c('router-link',{staticClass:"button xbtn is-medium is-transparent",attrs:{"tag":"a","to":"/records/machinery"}},[_c('icon',{attrs:{"name":"x"}})],1),(_vm.isNew)?_c('h2',{staticClass:"title"},[_vm._v("Nowy")]):_c('h2',{staticClass:"title"},[_vm._v("Edytuj")])],1),_c('div',{staticClass:"buttons level-right"},[_c('button',{staticClass:"button is-medium is-primary"},[_c('icon',{attrs:{"name":"check"}}),_c('span',[_vm._v("Zapisz")])],1)])])])],1),_c('div',{class:errors['name'] && errors['name'].length > 0
            ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
            : 'columns is-mobile is-variable is-3 is-multiline'},[_c('div',{staticClass:"column ym-column is-4-tablet is-12-mobile"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_short"}},[_vm._v(_vm._s(_vm.dictionary["table_order_device_name_field_label"]))]),(errors['name'] && errors['name'].length > 0)?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["name"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-8-tablet is-12-mobile"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"name"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.machinery.name),expression:"machinery.name"}],staticClass:"input is-medium is-uppercase",attrs:{"name":"name"},domProps:{"value":(_vm.machinery.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.machinery, "name", $event.target.value)}}})])],1)])])]),_c('div',{class:errors['type'] && errors['type'].length > 0
            ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
            : 'columns is-mobile is-variable is-3 is-multiline'},[_c('div',{staticClass:"column ym-column is-4-tablet is-12-mobile"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_short"}},[_vm._v(_vm._s(_vm.dictionary["table_order_device_type_field_label"]))]),(errors['type'] && errors['type'].length > 0)?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["type"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-8-tablet is-12-mobile"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"type"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.machinery.type),expression:"machinery.type"}],staticClass:"input is-medium is-uppercase",attrs:{"name":"type"},domProps:{"value":(_vm.machinery.type)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.machinery, "type", $event.target.value)}}})])],1)])])]),_c('div',{class:errors['vehicle_mileage'] && errors['vehicle_mileage'].length > 0
            ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
            : 'columns is-mobile is-variable is-3 is-multiline'},[_c('div',{staticClass:"column ym-column is-4-tablet is-12-mobile"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_short"}},[_vm._v(_vm._s(_vm.dictionary["table_order_device_vehicle_mileage_field_label"]))]),(
                errors['vehicle_mileage'] &&
                errors['vehicle_mileage'].length > 0
              )?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["vehicle_mileage"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-8-tablet is-12-mobile"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"vehicle_mileage"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.machinery.vehicle_mileage),expression:"machinery.vehicle_mileage"}],staticClass:"input is-medium is-uppercase",attrs:{"type":"number","name":"vehicle_mileage"},domProps:{"value":(_vm.machinery.vehicle_mileage)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.machinery, "vehicle_mileage", $event.target.value)}}})])],1)])])]),_c('div',{class:errors['serial'] && errors['serial'].length > 0
            ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
            : 'columns is-mobile is-variable is-3 is-multiline'},[_c('div',{staticClass:"column ym-column is-4-tablet is-12-mobile"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_short"}},[_vm._v(_vm._s(_vm.dictionary["table_order_device_serial_field_label"]))]),(errors['serial'] && errors['serial'].length > 0)?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["serial"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-8-tablet is-12-mobile"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"name":"serial"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.machinery.serial),expression:"machinery.serial"}],staticClass:"input is-medium is-uppercase",attrs:{"name":"serial"},domProps:{"value":(_vm.machinery.serial)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.machinery, "serial", $event.target.value)}}})])],1)])])])])]}}],null,false,2670908622)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }