var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dictionary && Object.keys(_vm.dictionary).length > 0)?_c('section',[_c('div',{staticClass:"ym-toptitle-box"},[_c('fixed-header',{attrs:{"threshold":0,"fixedClass":"ym-toptitle-fixed"}},[_c('div',{staticClass:"level ym-toptitle",attrs:{"id":"toptitle"}},[_c('div',{staticClass:"level-left"},[_c('router-link',{staticClass:"button xbtn is-medium is-transparent",attrs:{"tag":"a","to":"/records/machinery/"}},[_c('icon',{attrs:{"name":"x"}})],1),_c('h2',{staticClass:"title level-left"},[_vm._v(_vm._s(_vm.name))])],1),_c('div',{staticClass:"buttons level-right"},[_c('button',{staticClass:"button is-medium is-primary",on:{"click":function($event){return _vm.makeOrderFrom({ setDevice: _vm.machinery })}}},[_c('icon',{attrs:{"name":"plus"}}),_c('span',[_vm._v("Dodaj zlecenie")])],1),_c('router-link',{staticClass:"button is-medium is-light",attrs:{"tag":"a","to":{
              name: 'MachineryEdit',
              params: { id: parseInt(this.$route.params.id) },
            }}},[_c('icon',{attrs:{"name":"edit"}}),_c('span',[_vm._v("Edytuj")])],1),(_vm.selectADevice)?_c('a',{staticClass:"button is-medium is-dark",on:{"click":_vm.selectDevice}},[_c('icon',{attrs:{"name":"check"}}),_c('span',[_vm._v("Wybierz")])],1):_vm._e()],1)])])],1),(_vm.machinery)?_c('div',{staticClass:"ym-whitebg pa40"},[_c('div',{staticClass:"columns is-variable is-6"},[(_vm.machinery.name)?_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"label-small"},[_vm._v(" "+_vm._s(_vm.dictionary["table_order_device_name_field_label"])+" ")]),_c('p',{staticClass:"label is-uppercase"},[_vm._v(_vm._s(_vm.machinery.name))])]):_vm._e(),(_vm.machinery.type)?_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"label-small"},[_vm._v(" "+_vm._s(_vm.dictionary["table_order_device_type_field_label"])+" ")]),_c('p',{staticClass:"label is-uppercase"},[_vm._v(_vm._s(_vm.machinery.type))])]):_vm._e()]),_c('div',{staticClass:"columns is-variable is-6"},[(_vm.machinery.vehicle_mileage)?_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"label-small"},[_vm._v(" "+_vm._s(_vm.dictionary["table_order_device_vehicle_mileage_field_label"])+" ")]),_c('p',{staticClass:"label is-uppercase"},[_vm._v(_vm._s(_vm.machinery.vehicle_mileage))])]):_vm._e(),(_vm.machinery.serial)?_c('div',{staticClass:"column is-6"},[_c('p',{staticClass:"label-small"},[_vm._v(" "+_vm._s(_vm.dictionary["table_order_device_serial_field_label"])+" ")]),_c('p',{staticClass:"label is-uppercase"},[_vm._v(_vm._s(_vm.machinery.serial))])]):_vm._e()])]):_vm._e(),(
      _vm.machinery && _vm.machinery.order && Object.keys(_vm.machinery.order).length > 0
    )?_c('div',{staticStyle:{"margin-top":"24px"}},[_vm._m(0),_c('div',{staticClass:"ym-whitebg pa40"},[_c('div',{staticClass:"columns is-multiline is-variable is-3"},[(_vm.machinery.order.number)?_c('div',{staticClass:"column is-4"},[_c('p',{staticClass:"label-small"},[_vm._v("Numer zlecenia")]),_c('p',{staticClass:"label is-uppercase"},[_vm._v(_vm._s(_vm.machinery.order.number))])]):_vm._e(),(_vm.machinery.order.created_at)?_c('div',{staticClass:"column is-4"},[_c('p',{staticClass:"label-small"},[_vm._v("Data przyjęcia")]),_c('p',{staticClass:"label is-uppercase"},[_vm._v(_vm._s(_vm.machinery.order.created_at))])]):_vm._e(),(_vm.machinery.order.repared_at)?_c('div',{staticClass:"column is-4"},[_c('p',{staticClass:"label-small"},[_vm._v("Data naprawy")]),_c('p',{staticClass:"label is-uppercase"},[_vm._v(_vm._s(_vm.machinery.order.repared_at))])]):_vm._e(),(_vm.machinery.order.status_id)?_c('div',{staticClass:"column is-8"},[_c('p',{staticClass:"label-small"},[_vm._v("Status zlecenia")]),_c('p',{staticClass:"label is-uppercase",style:(_vm.statusColor())},[_vm._v(" "+_vm._s(_vm.statusName().toUpperCase())+" ")])]):_vm._e(),_c('div',{staticClass:"column is-2"},[_c('router-link',{staticClass:"button is-small40 is-dark",staticStyle:{"display":"inline-flex","padding":"0 1rem"},attrs:{"tag":"a","to":{
              name: 'ServiceDetails',
              params: { id: _vm.machinery.order.id },
            }}},[_vm._v("więcej")])],1)])])]):_vm._e()]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"level ym-toptitle",attrs:{"id":"toptitle"}},[_c('h2',{staticClass:"title level-left"},[_vm._v("Ostatnie zlecenie")])])
}]

export { render, staticRenderFns }